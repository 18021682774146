import React from "react";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { useAppsignal } from "@shared/useAppsignal";

import WidgetContainer from "./WidgetContainer";
import WidgetContext from "./WidgetContext";
import WidgetErrorFallback from "./WidgetErrorFallback";
import BubbleWidget from "./widgets/bubble/BubbleWidget";
import CheckinPanelWidget from "./widgets/checkin_panel/CheckinPanelWidget";
import CountdownWidget from "./widgets/countdown/CountdownWidget";
import EmailWidget from "./widgets/email/EmailWidget";
import GraphWidget from "./widgets/graph/GraphWidget";
import IframeWidget from "./widgets/iframe/IframeWidget";
import ImageWidget from "./widgets/image/ImageWidget";
import MicroformWidget from "./widgets/microform/MicroformWidget";
import OverviewWidget from "./widgets/overview/OverviewWidget";
import PieWidget from "./widgets/pie/PieWidget";
import ReportWidget from "./widgets/report/ReportWidget";
import TextWidget from "./widgets/text/TextWidget";
import TicketRequestWidget from "./widgets/ticket_request/TicketRequestWidget";

const Widget = (props) => {
  const { editMode = false, onUpdate, widget, widgetSettingsVisible = false, values } = props;
  const { attributes, listeners, isDragging, setNodeRef, transform, transition } = useSortable({
    id: widget.id,
    disabled: !editMode || widgetSettingsVisible
  });
  const { appsignal, ErrorBoundary } = useAppsignal();

  const sortableStyle = {
    transform: CSS.Translate.toString(transform),
    transition,
    zIndex: isDragging ? 1 : 0
  };

  const widgetContext = {
    editMode: editMode,
    onUpdate: onUpdate,
    widget: widget,
    values: values,
    dnd: {
      attributes: attributes,
      listeners: listeners,
      setNodeRef: setNodeRef,
      sortableStyle: sortableStyle
    }
  };

  const renderWidget = (widget) => {
    switch (widget?.widget_type) {
      case "bubble":
        return <BubbleWidget />;
      case "countdown":
        return <CountdownWidget />;
      case "email":
        return <EmailWidget />;
      case "graph":
        return <GraphWidget />;
      case "iframe":
        return <IframeWidget />;
      case "image":
        return <ImageWidget />;
      case "microform":
        return <MicroformWidget />;
      case "overview":
        return <OverviewWidget />;
      case "pie":
        return <PieWidget />;
      case "report":
        return <ReportWidget />;
      case "text":
        return <TextWidget />;
      case "ticketrequest":
        return <TicketRequestWidget />;
      case "checkinpanel":
        return <CheckinPanelWidget />;
      default:
        console.log(`cannot render widget type: ${widget?.widget_type}`);
        return <></>;
    }
  };

  return (
    <ErrorBoundary
      key={`error-boundary-widget-${widget.id}`}
      instance={appsignal}
      tags={{ tag: "widget" }}
      fallback={(error) => <WidgetErrorFallback error={error} id={widget.id} onUpdate={onUpdate} editMode={editMode} />}
    >
      <WidgetContext.Provider key={widget.id} value={widgetContext}>
        <WidgetContainer size={widget.widget_size} widgetComponent={renderWidget(widget)} editMode={editMode} />
      </WidgetContext.Provider>
    </ErrorBoundary>
  );
};

export default Widget;
