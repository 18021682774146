import React, { useState, useContext } from "react";
import PropTypes from "prop-types";

import { FormControl, Autocomplete, Stack, TextField } from "@mui/material";
import Modal from "react-modal";

import BubbleWidgetSettings from "@dashboard/widgets/bubble/BubbleWidgetSettings";
import CheckinPanelWidgetSettings from "@dashboard/widgets/checkin_panel/CheckinPanelWidgetSettings";
import CountdownWidgetSettings from "@dashboard/widgets/countdown/CountdownWidgetSettings";
import EmailWidgetSettings from "@dashboard/widgets/email/EmailWidgetSettings";
import GraphWidgetSettings from "@dashboard/widgets/graph/GraphWidgetSettings";
import IframeWidgetSettings from "@dashboard/widgets/iframe/IframeWidgetSettings";
import ImageWidgetSettings from "@dashboard/widgets/image/ImageWidgetSettings";
import MicroformWidgetSettings from "@dashboard/widgets/microform/MicroformWidgetSettings";
import OverviewWidgetSettings from "@dashboard/widgets/overview/OverviewWidgetSettings";
import PieWidgetSettings from "@dashboard/widgets/pie/PieWidgetSettings";
import ReportWidgetSettings from "@dashboard/widgets/report/ReportWidgetSettings";
import TextWidgetSettings from "@dashboard/widgets/text/TextWidgetSettings";
import TicketRequestWidgetSettings from "@dashboard/widgets/ticket_request/TicketRequestWidgetSettings";
import EventContext from "@event/EventContext";
import { renderButton, renderCancelButton } from "@shared/FormUtils";

import WidgetContext from "./WidgetContext";

const WidgetNewModal = (props) => {
  const { event } = useContext(EventContext).values;
  const checkinPanelEnabled = event?.features?.find((f) => f.slug === "checkinpanel")?.status === "enabled";

  const { onUpdate } = props;
  const [visible, setVisible] = useState(false);
  const [widgetType, setWidgetType] = useState("");
  const [widgetTypeSelected, setWidgetTypeSelected] = useState(false);

  Modal.setAppElement("#root");

  const widgetTypes = [
    "Bubble",
    "Countdown",
    "Email Statistics",
    "Graph",
    "Iframe",
    "Image",
    "Microform",
    "Overview",
    "Pie",
    "Report",
    "Text",
    "Ticket Request"
  ];
  if (checkinPanelEnabled) {
    widgetTypes.push("Checkin Panel");
  }

  const widgetContext = {
    widget: {},
    onUpdate: onUpdate
  };

  const resetModal = () => {
    setVisible(false);
    setWidgetType("");
    setWidgetTypeSelected(false);
  };

  const toggleModal = () => {
    setVisible(!visible);
  };

  const renderWidgetTypeSelect = () => {
    return (
      <div className="my-4">
        <FormControl fullWidth>
          <Autocomplete
            className="cursor-pointer"
            label={"Widget Type"}
            options={widgetTypes}
            onChange={(_evt, value) => {
              setWidgetType(value);
            }}
            renderInput={(params) => <TextField {...params} autoComplete="off" placeholder="Widget type" />}
            variant={"outlined"}
          />
        </FormControl>
      </div>
    );
  };

  const renderWidgetSettings = () => {
    // not working, play around with esbuild and dynamic imports later
    // const componentPath = `./widgets/${widgetType.toLowerCase()}/${widgetType}WidgetSettings`;
    // console.log(componentPath);
    // const SettingsComponent = lazy(() => import(componentPath));
    //
    // return (
    //   <Suspense fallback={<div>Loading...</div>}>
    //     <SettingsComponent closeModal={resetModal} />
    //   </Suspense>
    // );

    switch (widgetType) {
      case "Bubble":
        return <BubbleWidgetSettings closeModal={resetModal} />;
      case "Checkin Panel":
        return <CheckinPanelWidgetSettings closeModal={resetModal} />;
      case "Countdown":
        return <CountdownWidgetSettings closeModal={resetModal} />;
      case "Email Statistics":
        return <EmailWidgetSettings closeModal={resetModal} />;
      case "Graph":
        return <GraphWidgetSettings closeModal={resetModal} />;
      case "Iframe":
        return <IframeWidgetSettings closeModal={resetModal} />;
      case "Image":
        return <ImageWidgetSettings closeModal={resetModal} />;
      case "Microform":
        return <MicroformWidgetSettings closeModal={resetModal} />;
      case "Overview":
        return <OverviewWidgetSettings closeModal={resetModal} />;
      case "Pie":
        return <PieWidgetSettings closeModal={resetModal} />;
      case "Report":
        return <ReportWidgetSettings closeModal={resetModal} />;
      case "Text":
        return <TextWidgetSettings closeModal={resetModal} />;
      case "Ticket Request":
        return <TicketRequestWidgetSettings closeModal={resetModal} />;
      default:
        return <div>Invalid widget type selected: {`${widgetType}`}</div>;
    }
  };

  const renderStepOne = () => {
    return (
      <div className="sg-mgmt-modal-view">
        <h2>Select a widget type</h2>
        {renderWidgetTypeSelect()}
        <Stack spacing={2} direction="row">
          {renderButton(
            "Continue",
            () => {
              setWidgetTypeSelected(true);
            },
            { color: "secondary" }
          )}
          {renderCancelButton("Close", toggleModal, { color: "secondary" })}
        </Stack>
      </div>
    );
  };

  const renderStepTwo = () => {
    return <div className="sg-mgmt-modal-view">{renderWidgetSettings()}</div>;
  };

  const renderContent = () => {
    return <div>{widgetTypeSelected ? renderStepTwo() : renderStepOne()}</div>;
  };

  const renderModal = () => {
    return (
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title">Add Widget</div>
        <div className="sg-mgmt-modal-content">{renderContent()}</div>
      </div>
    );
  };

  return (
    <WidgetContext.Provider value={widgetContext}>
      <Modal
        className="sg-mgmt-modal"
        overlayClassName="sg-mgmt-modal-overlay"
        isOpen={visible}
        onRequestClose={resetModal}
        contentLabel={"Add Widget"}
      >
        {renderModal()}
      </Modal>
      {renderButton("Add Widget", toggleModal, { color: "secondary" })}
    </WidgetContext.Provider>
  );
};

WidgetNewModal.propTypes = {
  onUpdate: PropTypes.func.isRequired
};

export default WidgetNewModal;
