import React, { useContext } from "react";
import PropTypes from "prop-types";

import { Checkbox, FormControl, FormControlLabel } from "@mui/material";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import urljoin from "url-join";

import WidgetDataSetSelect from "@dashboard/form/WidgetDataSetSelect";
import WidgetHeaderField from "@dashboard/form/WidgetHeaderField";
import WidgetSettingsButtons from "@dashboard/form/WidgetSettingsButtons";
import WidgetSizeOptions from "@dashboard/form/WidgetSizeOptions";
import WidgetBoardContext from "@dashboard/WidgetBoardContext";
import WidgetContext from "@dashboard/WidgetContext";
import EventContext from "@event/EventContext";

const CheckinPanelWidgetSettings = (props) => {
  const { closeModal } = props;
  const { onUpdate, widget } = useContext(WidgetContext);
  const { widgetBoard } = useContext(WidgetBoardContext);
  const { apiRoot } = useContext(EventContext).values;
  const dataSetOptions = {
    participantsByStatus: [{ value: "registered", label: "Registered", order: 0 }]
  };

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    watch
  } = useForm({
    defaultValues: {
      widget_data_set: widget?.widget_data_set || "participantsByStatus",
      widget_header: widget?.widget_header || "Header",
      widget_position: widget?.widget_position || 0,
      widget_size: widget?.widget_size || 2,
      widget_config: {
        checkinpanel: widget?.widget_config?.checkinpanel || {}
      }
    }
  });

  const dataSet = watch("widget_data_set");

  const dataSets = [{ value: "participantsByStatus", label: "Participants By Status" }];

  const formConfig = (() => {
    if (widget.id) {
      return {
        method: "PATCH",
        url: urljoin(apiRoot, "/widgets", `/${widget.id}`)
      };
    }
    return {
      method: "POST",
      url: urljoin(apiRoot, "/widgets")
    };
  })();

  const submitFn = (submitData) => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

    // add in type
    submitData.widget_type = "checkinpanel";
    axios({
      method: formConfig.method,
      url: formConfig.url,
      data: {
        widget: submitData,
        widget_board_id: widgetBoard.id
      }
    })
      .then((_result) => {
        onUpdate();
        closeModal();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const renderMicroformSelections = () => {
    return (
      <div className="pb-12">
        <h3>Select from the options below</h3>
        {dataSetOptions[dataSet].map((data) => {
          return (
            <div className="flex flex-row" key={`${dataSet}-dataset-${data.value}`}>
              <div className="w-48">
                <FormControl>
                  <Controller
                    name={`widget_config[checkinpanel][${dataSet}][${data.value}][selected]`}
                    control={control}
                    defaultValue={false}
                    render={({ field: { value, onChange } }) => (
                      <FormControlLabel
                        label={data.label}
                        control={
                          <Checkbox checked={value} onChange={onChange} value={value} variant="outlined" size="small" />
                        }
                      />
                    )}
                  />
                </FormControl>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderForm = () => {
    return (
      <form onSubmit={handleSubmit(submitFn)}>
        <div className="mb-4 mt-4">
          <WidgetHeaderField control={control} errors={errors} />
          <WidgetDataSetSelect control={control} dataSets={dataSets} errors={errors} />
          <div>{renderMicroformSelections()}</div>
        </div>
        <WidgetSizeOptions control={control} disabled={true} />
        <WidgetSettingsButtons closeModal={closeModal} isSubmitDisabled={isSubmitting} isEdit={!!widget?.id} />
      </form>
    );
  };

  return (
    <div className="group relative bg-white">
      <div>
        <h2>Checkin Panel widget</h2>
      </div>
      <div>{renderForm()}</div>
    </div>
  );
};

CheckinPanelWidgetSettings.propTypes = {
  closeModal: PropTypes.func.isRequired
};

export default CheckinPanelWidgetSettings;
