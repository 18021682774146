import React, { useContext, useRef, useState } from "react";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import WidgetContentFrame from "@dashboard/WidgetContentFrame";
import WidgetContext from "@dashboard/WidgetContext";
import WidgetHeader from "@dashboard/WidgetHeader";
import CheckinPanelModal from "@dashboard/widgets/checkin_panel/CheckinPanelModal";
import WidgetSettings from "@dashboard/WidgetSettings";
import EventContext from "@event/EventContext";
import GrowlTable from "@shared/GrowlTable";
import { useIndividuals } from "@shared/hooks/useIndividuals";
import {
  useParticipantsMeta,
  useParticipantsTable,
  useRefreshParticipants,
  useUpdateParticipant
} from "@shared/hooks/useParticipants";
import Loading from "@shared/Loading";

import CheckinPanelWidgetSettings from "./CheckinPanelWidgetSettings";

// TODO: Further reduce duplication between MicroformWidget and PeopleEventParticipantIndex
const CheckinPanelWidget = () => {
  const { editMode, widget } = useContext(WidgetContext);
  const { apiRoot, event, rootUrl } = useContext(EventContext).values;
  const growlTableRef = useRef();
  const queryClient = useQueryClient();
  const enabled_metadata_fields = widget.widget_config.enabled_metadata_fields;
  const status_filter = widget.widget_config.checkinpanel.participantsByStatus;
  const [editParticipantId, setEditParticipantId] = useState(null);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const metaQuery = useParticipantsMeta(apiRoot, event.id);
  const participantsQuery = useParticipantsTable(apiRoot, event.id);
  const individualsQuery = useIndividuals(rootUrl);
  const refreshParticipants = useRefreshParticipants(event.id);
  const updateParticipant = useUpdateParticipant(event.id);

  const closeEditModal = () => {
    setEditModalVisible(false);
  };

  const resetEditModal = () => {
    closeEditModal();
    setEditParticipantId(null);
  };

  const filterParticipants = (participants) => {
    let filteredParticipants = participants;
    let statusesToKeep = [];
    Object.keys(status_filter).forEach((item) => {
      if (status_filter[item].selected) {
        statusesToKeep.push(item);
      }
    });
    filteredParticipants = filteredParticipants.filter((p) => statusesToKeep.includes(p.status) && !p.test_flag);
    return filteredParticipants;
  };

  const renderControls = () => {
    if (!editMode) return <></>;
    return <WidgetSettings label="Checkin Panel Settings" settingsComponent={CheckinPanelWidgetSettings} />;
  };

  const columns = [
    {
      field: "name_first",
      headerName: "First Name"
    },
    {
      field: "name_last",
      headerName: "Last Name"
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 200
    },
    {
      field: "company",
      headerName: "Company"
    },
    {
      field: "type",
      headerName: "Participant Type"
    },
    {
      field: "status",
      headerName: "Status",
      editable: true
    },
    {
      field: "attended",
      headerName: "Attended"
    },
    metaQuery?.data?.metadataFields
      .sort((a, b) => a.sort_order - b.sort_order)
      .flatMap((mf) => ({
        field: mf.slug,
        headerName: mf.label,
        valueGetter: (_value, row) => {
          return row?.metadata?.find((md) => md?.field_slug == mf.slug)?.value;
        }
      })),
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      minWidth: 180,
      getActions: (params) => [renderEditAction(params.row.id)]
    }
  ].flat();

  const defaultColumnVisibility = Object.fromEntries([
    ["vault_saved_at", false],
    ...metaQuery.data.metadataFields.map((mf) => [mf.slug, false])
  ]);

  const editPersonClick = (id) => {
    setEditParticipantId(id);
    setEditModalVisible(true);
  };

  const renderEditAction = (id) => {
    return (
      <>
        <span
          className="cursor-pointer"
          onClick={() => {
            editPersonClick(id);
          }}
        >
          Edit
        </span>
      </>
    );
  };

  const addIndividual = useMutation({
    mutationFn: () => {
      return true;
    },
    onSuccess: (_result, individual) => {
      queryClient.setQueryData(["individuals"], (existing) => {
        const newIndividuals = [...existing.individuals, individual];
        return { individuals: newIndividuals };
      });
    }
  });

  const addIndividualCallback = (individual) => {
    addIndividual.mutate(individual);
    refreshParticipants.mutate();
  };

  const renderEditModal = () => {
    if (!editParticipantId) {
      return <></>;
    }

    return (
      <CheckinPanelModal
        apiRoot={apiRoot}
        addIndividual={addIndividualCallback}
        enabledMetadataFields={enabled_metadata_fields}
        individuals={[]}
        isMicroform={true}
        participantId={editParticipantId}
        closeModal={closeEditModal}
        modalVisible={editModalVisible}
        metadataFields={metaQuery.data.metadataFields}
        regFields={metaQuery.data.regFields}
        resetModal={resetEditModal}
        rootUrl={rootUrl}
        tags={metaQuery.data.tags}
        types={metaQuery.data.types}
        updateFunc={(participant) => {
          updateParticipant.mutate({ participant: participant });
        }}
      />
    );
  };

  if (participantsQuery.isPending || individualsQuery.isPending || metaQuery.isPending) {
    return <Loading />;
  }

  if (participantsQuery.isError || individualsQuery.isError || metaQuery.isError) {
    console.log(participantsQuery.error);
    console.log(individualsQuery.error);
    console.log(metaQuery.error);
    return <div>Error loading data. Please refresh and try again.</div>;
  }

  return (
    <WidgetContentFrame fluidHeight>
      <WidgetHeader />
      <div className="mt-2">
        <GrowlTable
          checkboxSelection
          columns={columns}
          componentRef={growlTableRef}
          defaultColumnVisibility={defaultColumnVisibility}
          items={filterParticipants(participantsQuery.data.participants)}
          microform={true}
          tableName={`${widget.gid}-participants`}
          toolbarControls={[]}
          pageSizeInitial={10}
          pageSizeOptions={[5, 10, 25]}
        />
      </div>
      {/* {renderAddModal()} */}
      {renderEditModal()}
      {renderControls()}
    </WidgetContentFrame>
  );
};

export default CheckinPanelWidget;
